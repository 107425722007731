
.dashboard-page {
  padding: 15px;
  background-color: #f5f6f9;
  td {
    word-break: break-all;
  }
}

.cards__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .margin-bottom {
    margin-bottom: 30px;
  }
}
.cards__top-wrapper {
  width: 100%;
}

.cards__left {
  width: calc(66.5% - 15px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cards__right {
  width: calc(33.5% - 15px);
}
.dashboard-page_admin .cards__left {
  width: calc(66.5% - 10px);
}

.cards__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &_item {
    width: calc(50% - 15px);
  }
  &_top-item {
    width: calc(33.3333% - 15px);
  }
}
.dashboard-page__modal_fund .modal__container {
  min-height: 330px;
  max-height: 100vh;
}
.dashboard-page__modal_withdraw .modal__container {
  min-height: 275px;
}
.dashboard-page__modal_closed {
  opacity: 0;
}
.dashboard-page__modal {
  transition: opacity .25s ease;
}
.dashboard-page__modal_withdraw, .dashboard-page__modal_fund {
  opacity: 1;
}
.card .paginator {
  margin: auto 35px 18px auto;
  padding-top: 20px;
}
.dashboard-page .follow-card, .dashboard-page .invested-card {
  min-height: 548px;
}
.follow-card tr, .invested-card tr,
.historypaid-card tr, .investments-card tr {
  cursor: pointer;
}