.signable__tabs {
  margin-bottom: 23px;
}
.signable__tabs > button {
  display: inline-block;
  border: 0 none;
  border-bottom: 2px solid transparent;
  color: #414141;
  opacity: 0.5;
  padding: 10px 34px;
  font-family: "Corsica Rambler LX";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
  text-align: center;
  box-shadow: none;
  cursor: pointer;
  background: transparent;
  transition: opacity 0.2s ease, border-color 0.2s ease;
}
.signable__tabs > button.active {
  border-color: #2C2C2C;
  opacity: 1;
}
.signable__tabs > button:not(:last-child) {
  margin-right: 34px;
}

.signable__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}