.kyc-wrapper {
  border-radius: 11px;
  background-color: #fefefe;
  overflow: hidden;
  padding: 55px 65px;
  max-width: 920px;
  min-width: 920px;
  min-height: 820px;
  position: relative;
  margin: 0 auto;
}

.kyc-wrapper .control {
  padding-bottom: 20px;
}

.kyc-header__item, .kyc-header__item_active {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: normal;
  text-align: center;
  color: #D4D9DD;
  text-transform: uppercase;
  height: 29px;
  font-family: "Corsica Rambler LX";
  font-weight: 600;
}

.kyc-header__item_active {
  color: #1B2125;
  border-bottom: 1px solid #1B2125;
}

.kyc-header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 51px;
  border-bottom: 1px solid #E4EAEE;
}

.kyc-header__item_nodisplay {
  display: none;
}

.kyc-stage-inputs__input {
  position: relative;
  display: block;
}

.kyc-stage-wrapper select, .kyc-stage-wrapper input {
  background: none;
  width: 345px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #D7D7D7;
  outline: none;
  padding: 16px;
  appearance: none;
  display: block;
}

.kyc-stage-wrapper select {
  padding: 0 16px;
  cursor: pointer;
}

.kyc-stage-inputs-wrapper {
  display: flex;
  align-items: center;
}

.kyc-stage-inputs__title {
  min-width: 161px;
  padding-right: 30px;
  text-align: right;
  padding-bottom: 20px;
  color: #383838;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.select-custom {
  position: absolute;
  font-size: 21px;
  color: #A6A6A6;
  right: 15px;
  transform: translateY(-1px);
}

select:active + .select-custom {
  transform: rotate(180deg) translateY(-5px);
}

select:focus + .select-custom {
  color: #315EFB;
}

.title {
  color: #323232;
  font-family: "Corsica Rambler LX";
  font-size: 20px;
  line-height: 34px;
  padding: 32px 0 45px 0;
  position: relative;
}

.react-datepicker-wrapper ~ svg {
  fill: #D7D7D7;
  position: absolute;
  right: 15px;
}

.control .react-datepicker__input-container input:focus {
  border: 2px solid #315EFB !important;
}

.react-datepicker__tab-loop ~ svg {
  fill: #315EFB;
}

.svg_caret {
  width: 12px;
  height: 7px;
  fill: #D7D7D7;
  position: absolute;
  right: 15px;
}

select:focus:active + .svg_caret {
  transform: rotate(180deg);
}

select:focus + .svg_caret {
  fill: #315EFB;
}

.kyc-stage-inputs__input:has(.react-datepicker-wrapper):hover {
  cursor: pointer;
}

.kyc-buttons {
  display: flex;
}

.kyc-buttons {
  display: flex;
  justify-content: flex-end;
}

.kyc-buttons .kyc__back-button {
  margin-right: auto;
}

.kyc-stage-wrapper {
  position: relative;
  height: 100%;
}

.kyc-buttons {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 41px;
  right: 67px;
  width: 670px;
  width: calc(100% - 67px);
}

.kyc-stage-wrapper {
  position: relative;
}

.personal-details__join {
  display: flex;
}

.personal-details__join select {
  width: 70px;
}

.personal-details__join .select-custom {
  display: none;
}

.personal-details__join .kyc-stage-inputs-wrapper:last-child .kyc-stage-inputs__title {
  display: none;
}

.personal-details__join .kyc-stage-inputs-wrapper:last-child input {
  width: 275px;
}

.kyc-transitions, .kyc-inner {
  position: absolute;
  top: 105px;
  left: 0;
  padding: 0 65px;
  width: 100%;
  height: calc(100% - 100px);
}

.kyc-transitions {
  transition: all linear 0.25s;
}

.kyc-current {
  transform: translate3d(0, 0, 0);
}

.kyc-after {
  transform: translate3d(100%, 0, 0);
}

.kyc-before {
  transform: translate3d(-100%, 0, 0);
}

.fileupload-title {
  margin: 0 18px;
  font-size: 15px;
  line-height: 20px;
  color: #43434A;
}

.proof-of-identity__country .control {
  width: 455px;
}

.proof-of-identity__country {
  padding: 0 0 5px 93px;
}

.proof-of-identity__country-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #383838;
  min-width: 100px;
  margin-right: 30px;
}

.proof-of-identity__fileupload-wrapper {
  padding: 0 31px;
}

.proof-of-identity__fileupload-caption {
  margin-bottom: 10px;
  display: flex;
  margin-left: 113px;
}

.proof-of-identity__fileupload-caption-second {
  margin-top: 45px;
  margin-bottom: 30px;
}

.proof-of-identity__fileupload-caption-title,
.proof-of-identity__fileupload-caption-info {
  font-weight: 500;
  margin-right: 28px;
  font-size: 14px;
  line-height: 24px;
  color: #383838;
}

.proof-of-identity__fileupload-caption-info {
  font-weight: 300;
  display: flex;
}

.proof-of-identity__fileupload-caption-info .radiobutton-container {
  margin-right: 26.75px;
}

.bank-details-segment:not(:first-child) {
  margin-top: 15px;
}

.bank-details-title {
  min-height: 50px;
}

.kyc-wrapper_high {
  min-height: 1020px;
  margin-bottom: 120px;
}

.kyc-wrapper_competence {
  min-height: 1760px;
  margin-bottom: 120px;
}

.bank-details-title {
  font-size: 20px;
  line-height: 34px;
  color: #323232;
  min-width: 215px;
  margin-right: 50px;
  position: relative;
}

.bank-details-segment .kyc-stage-inputs-wrapper {
  padding-left: 40px;
}

.bank-details-header-info {
  max-width: 300px;
  font-size: 12px;
  font-weight: 300;
  line-height: 19px;
  margin-bottom: 10px;
}

.bank-details-header-info b {
  font-weight: bold;
}

.fileupload-note {
  font-size: 13px;
  padding-top: 5px;
}

.review-info__note {
  font-weight: 300;
  font-size: 12px;
  line-height: 19px;
  color: #323232;
}

.review-info__note b {
  font-weight: 700;
}

.bank-details-header {
  display: flex;
}

.competence-review__item-answers {
  font-weight: 300;
  margin-left: 70px;
  display: flex;
  width: calc(100% - 120px);
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: #575757;
  margin-top: 12px;
}

.competence-review__item-answers .radiobutton-container {
  padding-top: 1px;
  width: 33.3333%;
}

.competence-review__item-question {
  padding-left: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #383838;
}

.competence-review__questions-wrapper .control:not(:last-child) {
  border-left: 1px solid #274BC8;
}

.competence-review__questions-wrapper .control {
  margin-left: 31px;
}

.competence-review__item-numberball {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: -12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #274BC8;
  background: white;
  z-index: 2;
  color: #274BC8;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

.competence-review__item_active .competence-review__item-numberball {
  color: white;
  background: #274BC8;
}

.reviewInfo__note {
  font-weight: 3000;
  font-size: 12px;
  line-height: 19px;
  color: #323232;
}

.review-info__row {
  display: flex;
}

.review-info__row-left {
  min-width: 330px;
  margin-right: 25px;
}

.review-info__row-right {
  max-width: 350px;
}

.review-info {
  padding-top: 32px;
}

.review-info__row:first-child {
  margin-bottom: 22px;
}

.review-info__row:nth-child(2) {
  margin-bottom: 40px;
}

.review-info__stages-info-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  margin-bottom: 9px;
  color: #323232;
}

.review-info__stages-info-item {
  margin-top: 15px;
  font-size: 14px;
  line-height: 24px;
  color: #383838;
}

.review-info__title {
  font-family: "Corsica Rambler LX";
  font-size: 20px;
  line-height: 34px;
  color: #323232;
}

.review-info__stages-info-item {
  position: relative;
  overflow: visible;
  display: flex;
  align-items: baseline;
  max-width: 330px;
  word-break: break-all;
}

.review-info__stages-preview-icon {
  margin: 0 15px;
  cursor: pointer;
  position: relative;
}

.review-info__stages-preview {
  position: absolute;
  width: 350px;
  height: 250px;
  padding: 20px;
  background: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  display: none;
  top: 100%;
  left: -350px;
}

.overlow-fileupload .control:last-child .review-info__stages-preview {
  top: -250px;
}

.review-info__stages-preview img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.review-info__stages-preview-icon svg:hover ~ .review-info__stages-preview {
  display: block;
  z-index: 2;
}

.kyc__finished {
  background-color: #3955B7;
  width: 100%;
  height: 572px;
  padding-top: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  page-break-after: always;
  justify-content: flex-start;
  left: -65px;
  width: calc(100% + 130px);
}

.kyc__finished > div {
  margin-bottom: 30px;
  text-align: center;
}

.kyc__finished-thankyou {
  font-family: "Corsica Rambler LX";
  font-weight: 500;
  font-size: 52px;
  line-height: 32px;
  color: white;
}

.kyc__finished-thankyou {
  font-family: "Corsica Rambler LX";
  font-weight: 600;
  font-size: 52px;
  line-height: 32px;
  color: white;
}

.kyc__finished-addition {
  font-weight: 300;
  font-family: "Roboto";
  font-size: 18px;
  line-height: 30px;
  max-width: 375px;
  color: #EFEFEF;
}

.kyc__finished-status-up {
  font-weight: 300;
  font-family: "Roboto";
  font-size: 12px;
  line-height: 14px;
  color: #EFEFEF;
}

.kyc__finished-status-bottom {
  font-weight: 700;
  font-family: "Roboto";
  font-size: 16px;
  line-height: 19px;
  margin-top: 5px;
  color: #EFEFEF;
}

.bank-details [data-required]::after {
  content: attr(data-required);
  font-family: Roboto;
  font-weight: 300;
  font-size: 12px;
  line-height: 26px;
  color: #323232;
  position: absolute;
  top: 22px;
  left: 0;
  width: 250px;
}

.kyc-stage-wrapper form {
  font-family: Roboto;
}

.kyc-wrapper .review-info__stages-preview-icon {
  width: 16px;
  height: 9px;
}

.svg_confetti {
  width: 50px;
  height: 50px;
  fill: white;
}

.svg_calendar {
  width: 13px;
  height: 13px;
}

.kyc-tooltip-wrapper {
  position: relative;
}

.kyc-tooltip-inner-wrapper {
  position: absolute;
  width: 263px;
  padding-bottom: 25px;
  left: 10px;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  font-size: 14px;
  line-height: 23px;
  color: #303030;
  display: none;
  padding-left: 27px;
  z-index: 2;
  background: white;
}

.kyc-tooltip {
  padding: 26px 18px 0 16px;
  margin: 0;
}

.kyc-tooltip-icon {
  cursor: pointer;
  margin-right: 5px;
}

.kyc-tooltip-icon:hover .kyc-tooltip-inner-wrapper {
  display: block;
}

.proof-of-identity__fileupload-caption-title {
  display: flex;
}

.svg_eye {
  width: 16px;
  height: 9px;
}

.kyc-page__inline-wrapper {
  padding: 100px 0 120px 0;
  position: relative;
}
.kyc-page__inline-wrapper_loading {
  opacity: 0.5;
  pointer-events: none;
}

.kyc-page__error {
  position: absolute;
  top: 65px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.react-datepicker__tab-loop + .svg_calendar, .react-datepicker-wrapper ~ .svg-calendar_blue {
  fill: #315EFB;
}

.kyc-wrapper_ultrahigh {
  height: 1220px;
}

.bank-details-segment {
  padding-top: 30px;
}

.control--error .fileupload-button {
  border: 1px dashed red;
}

.control--error .competence-review__item-numberball {
  background: red;
}

.control--error .dropzone:focus {
  outline: 0;
}

.competence-review__common-error {
  padding: 25px;
  color: red;
}

.kyc-stage__title {
  padding: 40px 0;
}

.competence-review__questions-wrapper .control__error {
  display: none;
}

.svg_backarrow {
  width: 14px;
  height: 13px;
  fill: #4C7ACB;
}

.proof-of-identity .dropzone {
  margin-right: 32px;
  width: calc(50% - 32px);
}

.proof-of-identity .fileupload-info__filename, .review-info .fileupload-info__filename {
  word-break: unset;
  max-height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.proof-of-identity .fileupload-info__filename_overflown:hover,
.review-info .fileupload-info__filename_overflown:hover {
  white-space: normal;
}

.proof-of-identity .fileupload-info__filename_overflown:hover::after,
.review-info .fileupload-info__filename_overflown:hover::after {
  content: attr(data-filename);
  word-break: break-all;
  max-height: 300px;
  max-width: 422px;
  position: absolute;
  overflow: auto;
  background: white;
  opacity: 1;
  z-index: 2;
  padding: 10px;
  top: 0;
  right: 0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}

.investor-type__label-inner {
  display: flex;
}
.investor-type__label-inner ol {
  list-style-type: none;
}
.investor-type__label-inner ol > li {
  counter-increment: section;
  margin: 15px;
}
.investor-type__label-inner ul > li {
  margin: 10px;
}

.investor-type__list-alpha > li:before {
  content: counter(section, lower-alpha) ")  ";
  font-weight: bold;
}
.investor-type__list-alpha > li > ul {
  list-style-type: lower-roman;
}

.investor-type__list-roman > li:before {
  content: counter(section, lower-roman) ")  ";
  font-weight: bold;
}

.investor-type__item {
  border: 2px solid #DDE3E8;
  padding: 35px 40px;
}
.investor-type__item_active {
  border-color: #274BC8;
}

.kyc-stage__investor-title {
  color: #323232;
  font-family: "Corsica Rambler LX";
  font-size: 20px;
  line-height: 34px;
  padding-top: 40px;
}

.kyc-stage__investor-explain {
  color: #323232;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
}

.investor-type__label-left {
  min-width: 160px;
  margin-right: 30px;
  color: #252525;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
}

.investor-type__label-right {
  color: #252525;
  font-size: 14px;
  line-height: 22px;
}

.kyc-stage__investor-type .control {
  align-items: flex-start;
  padding-top: 35px;
}

.kyc-wrapper_investor-type {
  min-height: 3200px;
}

.font-medium {
  font-weight: 500;
}

.investor-type__item-wrapper {
  padding-top: 40px;
}

.kyc__finished + .kyc-buttons {
  bottom: 100px;
}

.risk-link {
  text-decoration: none;
}
.risk-link:hover {
  text-decoration: underline;
}

.phone-number-disclaimer {
  position: absolute;
  top: -15px;
  font-size: 11px;
}