body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.platform {
  min-height: 100vh;
  display: flex;

  main {
    flex-grow: 1;
    position: relative;
    padding-bottom: 165px;
    display: flex;
    flex-direction: column;
  }
  .footer-wrapper {
    position: absolute;
    bottom: 0;
  }
}
.main-nav {
  flex: 0 0 255px;
}
.main-nav ul a:focus::after {
  border: none;
}
.disable-filters {
  .list-headers__status-selector,
  .list-headers__header1-search,
  .list-headers__trigger,
  .list-headers__header2-controls,
  .paginator-controls  {
    pointer-events: none;
  }
}
.data-loading {
  opacity: .7;
}
.table__no-result {
  text-align: center;
  padding-top: 50px;
  font-weight: 700;
  font-size: 20px;
}