.projects-list__wrapper {
  font-family: "Roboto";
}

.projects-list__wrapper .control {
  padding-bottom: 12px;
}

.projects-list__wrapper .control > input[type=checkbox] ~ span {
  line-height: 24px;
}

.projects-list__arrow-right {
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid #333333;
}

.projects-list__arrow-left {
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-right: 3px solid #333333;
}

.project-list__trigger-indicator_opened {
  transform: translateY(4px);
  width: 12px;
}

.project-list__trigger-indicator {
  width: 12px;
  transform: rotate(180deg) translateY(4px);
}

.projects-list__header1 {
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}

.projects-list__header1-caption {
  font-family: Corsica Rambler LX;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #333333;
}

.projects-list__header1-search {
  width: 160px;
  height: 44px;
  position: relative;
}
.projects-list__header1-search .img_search {
  width: 17.5px;
  height: 17.5px;
  fill: #4B74FF;
  position: absolute;
  top: 13px;
  left: 30px;
}

.projects-list__header1-input {
  width: 100%;
  height: 100%;
  border: 1px solid #4B74FF;
  border-radius: 24px;
  padding: 15px 40px 15px 55px;
  outline: 0;
  color: #4B74FF;
  transition: all linear 0.125s;
}

.projects-list__header1-input:valid, .projects-list__header1-input:focus {
  padding: 15px;
  width: 110%;
  transform: translateX(-10%);
}

.projects-list__header1-input:valid ~ .img_search,
.projects-list__header1-input:focus ~ .img_search {
  display: none;
}

.projects-list__header1-input::placeholder {
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.5px;
  color: #4B74FF;
}

.projects-list__header1-input:focus::placeholder {
  color: transparent;
}

.projects-list__header2 {
  height: 62px;
  display: flex;
  align-items: center;
  padding: 0 18px 0 78px;
  justify-content: space-between;
}

.project-list__selector-dropdown {
  position: absolute;
}

.project-list__trigger {
  width: 94px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #4B74FF;
  border-radius: 2px;
  font-size: 14px;
  line-height: 24px;
  color: #4B74FF;
  padding: 4px 15px 4px 20px;
  cursor: pointer;
}

.project-list__selector-dropdown {
  z-index: -1;
  opacity: 0;
  transform: translateY(-5px);
  transition: transform linear 0.125s;
  padding: 20px 20px 0 20px;
  cursor: pointer;
}

.project-list__selector-dropdown_opened {
  z-index: 2;
  opacity: 1;
  width: 236px;
  height: 188px;
  border-radius: 4px;
  background: white;
  transform: translateY(0);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
}

.project-list__status-selector-apply {
  text-align: right;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
  font-weight: 400;
  text-decoration: underline;
}

.projects-list__header2-info {
  padding-right: 9px;
  font-weight: 400;
}

.projects-list__header2-info span {
  font-weight: 700;
}

.projects-list__header2-control, .projects-list__header2-control_disabled {
  height: 32px;
  width: 32px;
  border: 1px solid #C6CBD4;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.projects-list__header2-paging {
  display: flex;
  align-items: center;
}

.projects-list__header2-control_disabled {
  pointer-events: none;
}

.projects-list__main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #DDE3EC;
  padding-bottom: 145px;
}

.projects-list__main .project-card {
  width: 350px;
  border-radius: 11px;
}

.projects-list__card-wrapper {
  padding-top: 45px;
  min-width: 360px;
  cursor: pointer;
  width: 23%;
}

.projects-list__main .project-card__title {
  padding-right: 15px;
  min-height: 57px;
}

.projects-list__header2-controls {
  display: flex;
  align-items: center;
}

.no-search-result {
  background-color: white;
  width: 100%;
  text-align: center;
}