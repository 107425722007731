.profile-wrapper {
  background-color: #fefefe;
  overflow: hidden;
  min-height: 1400px;
  position: relative;
  margin: 0 auto;
  background-color: #F5F6F9;
}

.profile-wrapper select:disabled, .profile-wrapper input:disabled {
  color: #AEAEAE;
}

.profile-wrapper .control {
  padding-bottom: 20px;
}

.profile-stage-wrapper select, .profile-stage-wrapper input {
  background: white;
  width: 345px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #D7D7D7;
  outline: none;
  padding: 16px;
  appearance: none;
  display: block;
}

.profile-stage-wrapper select {
  padding: 0 16px;
  cursor: pointer;
}

.profile-stage__title {
  color: #323232;
  font-family: "Corsica Rambler LX";
  font-size: 20px;
  line-height: 34px;
  padding: 32px 0 45px 0;
  position: relative;
}

.profile-stage-wrapper {
  position: relative;
  height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 45px;
}

.profile-transitions {
  transition: all linear 0.25s;
  position: absolute;
  top: 151px;
  left: 0;
  padding: 0 65px;
  width: 100%;
  height: calc(100% - 100px);
}

.profile-current {
  transform: translate3d(0, 0, 0);
}

.profile-after {
  transform: translate3d(100%, 0, 0);
}

.profile-before {
  transform: translate3d(-100%, 0, 0);
}

.fileupload-title {
  margin: 0 18px;
  font-size: 15px;
  line-height: 20px;
  color: #43434A;
}

.profile-stage__fileupload-note {
  font-size: 13px;
  padding-top: 5px;
}

.profile__submit-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 42px 74px 0 0;
}

.profile-stage__info-wrapper {
  display: flex;
  min-height: 60px;
  padding: 10px 0;
  word-break: break-word;
  align-items: center;
  border-bottom: 1px solid #EBEBEB;
}

.profile-stage__bank-details-segment:last-child .profile-stage__info-wrapper:last-child,
.profile-stage__personal-details .profile-stage__info-wrapper:last-child {
  border-bottom: none;
}

.profile-stage__info-wrapper .profile-stage-inputs__title {
  width: 200px;
  text-align: left;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #A3A3A3;
}

.profile-stage-inputs__info {
  width: 355px;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  min-height: 40px;
  display: flex;
  align-items: center;
  max-height: 85px;
  color: #242424;
  overflow: auto;
}

.profile-stage__admin-form {
  margin-left: 100px;
  background: white;
  padding: 25px 40px;
}

.profile-stage__inputs-wrapper {
  display: flex;
  align-items: center;
}

.profile-stage__user-form {
  width: 500px;
  margin-left: 40px;
}