.total-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 28px 16px 28px;
  height: 105px;
  color: #3e3e3e;
  font-size: 16px;
  letter-spacing: 1.33px;
  line-height: 19px;
}

.total-info__money {
  color: #373737;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 2.67px;
  line-height: 38px;
}

.total-info__percent {
  align-self: flex-end;
  strong {
    font-weight: 500;
  }
}
