.main-nav {
  background-color: #333;
  transition: max-width .2s ease;
  max-width: 0;
  overflow: hidden;
  padding: 30px 8px;
  ul {
    margin: 45px 0 0 0;
    padding: 0;
    li {
      display: block;
      a {
        display: block;
        height: 40px;
        line-height: 40px;
        padding: 0 24px;
        text-decoration: none;
        opacity: 0.3;
        color: #FBFBFB;
        font-family: Roboto;
        font-size: 14px;
        &.active {
          background-color: #4B74FF;
          color: #FBFBFB;
          font-weight: bold;
          opacity: 1;
        }
      }
    }
  }
}

.main-nav.visible {
  max-width: 500px;
}

.main-nav__logo {
  padding-left: 24px;
}
.main-nav_disabled {
  pointer-events: none;
}
