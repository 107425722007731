.message {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 5px 10px;
  margin-bottom: 20px;
}

.message__error {
  border-color: #E6364B;
  color: #E6364B;
}