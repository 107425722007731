.custom-autocomplete {
  width: 100%;
}
.custom-autocomplete input {
  border-radius: 4px;
  border: 1px solid #D7D7D7;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 0 16px;
  color: #2D313D;
}
.custom-autocomplete input:focus {
  border: 2px solid #315EFB;
}