.pledgeproc-wrapper {
  border-radius: 11px;
  background-color: #fefefe;
  overflow: hidden;
  padding: 55px 50px 55px 60px;
  max-width: 840px;
  min-width: 840px;
  min-height: 784px;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.pledgeproc-wrapper .control {
  padding-bottom: 20px;
}

.pledgeproc-header__item, .pledgeproc-header__item_active {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: normal;
  text-align: center;
  color: #D4D9DD;
  text-transform: uppercase;
  height: 29px;
  font-family: "Corsica Rambler LX";
  font-weight: 600;
}

.pledgeproc-header__item_active {
  color: #1B2125;
  border-bottom: 1px solid #1B2125;
}

.pledgeproc-header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 51px;
  border-bottom: 1px solid #E4EAEE;
}

.pledgeproc-header__item_nodisplay {
  display: none;
}

.pledgeproc-stage-wrapper select,
.pledgeproc-stage-wrapper input[type=text],
.pledgeproc-stage-wrapper input[type=number] {
  background: none;
  width: 345px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #D7D7D7;
  outline: none;
  padding: 16px;
  appearance: none;
  display: block;
}

.pledgeproc-stage-wrapper select {
  padding: 0 16px;
  cursor: pointer;
}

.pledgeproc-stage-inputs-wrapper {
  display: flex;
  align-items: center;
}

.pledgeproc-stage-inputs__title {
  min-width: 161px;
  padding-right: 30px;
  text-align: right;
  padding-bottom: 20px;
  color: #383838;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.select-custom {
  position: absolute;
  font-size: 21px;
  color: #A6A6A6;
  right: 15px;
  transform: translateY(-1px);
}

select:active + .select-custom {
  transform: rotate(180deg) translateY(-5px);
}

select:focus + .select-custom {
  color: #315EFB;
}

.pledgeproc-stage__title {
  color: #323232;
  font-family: "Corsica Rambler LX";
  font-size: 20px;
  line-height: 34px;
  padding: 32px 0 5px 0;
  position: relative;
}

.react-datepicker-wrapper ~ svg {
  fill: #D7D7D7;
  position: absolute;
  right: 15px;
}

.pledge-current-step {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pledge-current-step.exited {
  display: none;
}
.pledge-current-step.entering {
  transform: translateX(100%);
}
.pledge-current-step.exiting {
  transform: translateX(-100%);
}

.back > .pledge-current-step.entering {
  transform: translateX(-100%);
}
.back > .pledge-current-step.exiting {
  transform: translateX(100%);
}

.pledge-steps {
  position: relative;
  flex-grow: 1;
}

.pledgeproc-buttons {
  display: flex;
  justify-content: flex-end;
}

.pledgeproc-buttons .kyc__back-button {
  margin-right: auto;
}

.pledgeproc-stage-wrapper {
  position: relative;
  height: 100%;
}

.pledgeproc-transitions {
  transition: all linear 0.25s;
  position: absolute;
  top: 105px;
  left: 0;
  padding: 0 65px;
  width: 100%;
  height: calc(100% - 100px);
}

.pledgeproc-current {
  transform: translate3d(0, 0, 0);
}

.pledgeproc-after {
  transform: translate3d(100%, 0, 0);
}

.pledgeproc-before {
  transform: translate3d(-100%, 0, 0);
}

.fileupload-title {
  margin: 0 18px;
  font-size: 15px;
  line-height: 20px;
  color: #43434A;
}

.pledgeproc-stage__proof-of-identity__country .control {
  width: 345px;
}

.pledgeproc-stage__proof-of-identity__country {
  padding: 0 0 5px 93px;
}

.pledgeproc-stage__proof-of-identity__country-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #383838;
  min-width: 100px;
  margin-right: 30px;
}

.pledgeproc-stage__proof-of-identity__fileupload-wrapper {
  padding: 0 31px;
}

.pledgeproc-stage__proof-of-identity__fileupload-caption {
  margin-bottom: 10px;
  display: flex;
  margin-left: 113px;
}

.pledgeproc-stage__proof-of-identity__fileupload-caption-second {
  margin-top: 45px;
  margin-bottom: 30px;
}

.pledgeproc-stage__proof-of-identity__fileupload-caption-title,
.pledgeproc-stage__proof-of-identity__fileupload-caption-info {
  font-weight: 500;
  margin-right: 28px;
  font-size: 14px;
  line-height: 24px;
  color: #383838;
}

.pledgeproc-stage__proof-of-identity__fileupload-caption-info {
  font-weight: 300;
  display: flex;
}

.pledgeproc-stage__proof-of-identity__fileupload-caption-info .radiobutton-container {
  margin-right: 26.75px;
}

.pledgeproc-stage__bank-details-segment:not(:first-child) {
  margin-top: 15px;
}

.pledgeproc-stage__bank-details-title {
  min-height: 50px;
}

.pledgeproc-wrapper_high {
  min-height: 906px;
}

.pledgeproc-wrapper_medium {
  min-height: 890px;
}

.pledgeproc-stage__amount-row {
  height: 100px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  border: 1px solid #DDE3E8;
  border-radius: 3px;
  margin-top: 30px;
}

.pledgeproc-stage__amount-row .control__error {
  min-width: 350px;
}

.pledgeproc-stage__amount-row_active {
  border: 2px solid #274BC8;
}

.pledgeproc-stage__amount-title {
  min-width: 210px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #414141;
}

.pledgeproc__back-button {
  margin-right: auto;
}

.pledgeproc-stage__amount-sum, .pledgeproc-stage__amount-input {
  min-width: 120px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #1B2125;
}

.pledgeproc-stage__amount-input input {
  width: 82px;
  height: 40px;
  border-radius: 4px;
  border: 2px solid #E2E2E2;
}

.pledgeproc-stage__amount-input .control {
  padding-top: 20px;
}

.pledgeproc-stage__terms-text, .pledgeproc-stage__esign-text {
  background: #F7F7F7;
  padding: 21px 44px 39px 44px;
  max-height: 450px;
  overflow: auto;
  font-weight: 300;
  font-size: 14px;
  line-height: 23px;
  color: #414141;
}

.pledgeproc-stage__terms-text span, .pledgeproc-stage__esign-text span {
  font-size: 16px;
  font-weight: 500;
}

.pledgeproc-stage__terms-agreement {
  padding-top: 30px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.11px;
  color: #656D78;
}

.pledgeproc-stage__esign-upload {
  padding-top: 24px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.5px;
}

.pledgeproc-stage__esign-upload {
  padding-bottom: 7px;
}

.pledgeproc__sign-button {
  margin-right: auto;
}

.svg_right-arrow {
  display: inline-block;
  width: 24px;
  height: 16px;
  vertical-align: middle;
  margin-left: 10px;
}

.pledgeproc-transitions_wide {
  padding: 0;
}

.pledgeproc-stage__pledge-iframe {
  padding: 5px;
  border: 0;
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.pledge-step-title {
  color: #323232;
  font-family: "Corsica Rambler LX";
  font-size: 20px;
  letter-spacing: 0;
  line-height: 34px;
  font-weight: normal;
  margin: 20px 0;
}

.pledge-step-error {
  color: red;
  margin: 10px 0;
}

.pledge-amount-disabled {
  position: relative;
}
.pledge-amount-disabled::after {
  content: "";
  display: block;
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}