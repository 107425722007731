.admin-payments__table {
  width: 100%;
  border-collapse: collapse;

  thead {
    color: rgba(51, 51, 51, 0.5);
    th {
      padding-bottom: 20px;
    }
  }
  tbody {
    td {
      padding: 28px 0;
      vertical-align: top;
      text-align: center;
    }
    tr {
      color: #333333;
      border-left: 4px solid transparent;
      &:not(.expanded):nth-child(2n) {
        background-color: #F2F3F5;
      }
      &:not(.expanded):hover {
        background-color: #edf1ff;
        border-color: #4B74FF;
      }
    }
  }
  .d-column {
    span {
      display: block;
      margin-bottom: 10px;
    }
  }
  .FAILED {
    color: #D04F2B;
    text-transform: lowercase;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .SUCCEEDED {
    color: #84BC74;
    text-transform: lowercase;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .PENDING {
    color: #FDBB22;
    text-transform: lowercase;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .icon {
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
    & > div {
      cursor: pointer;
    }
  }
  .name {
    color: #4B74FF;
    display: flex;
    align-items: center;
    span {
      margin-right: 10px;
    }
  }
  .content-left {
    text-align: left;
  }
  .icon-plus {
    width: 12px;
    height: 12px;
    position: relative;
    border: none;
    background-color: transparent;
    appearance: none;
    outline: none;
    &:after, &:before {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      left: 0;
      top: 50%;
      background-color: #4B74FF;
      transition: 0.3s ease;
    }
    &:after {
      transform: rotate(90deg);
    }
  }
  .icon-plus.active {
    &:after {
      transform: rotate(180deg);
    }
  }
}
