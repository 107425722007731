.success-modal__content {
  padding: 60px 36px 76px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.success-modal__content.account {
  padding: 0;
}

.success-modal__title {
  font-size: 52px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 27px;
  margin-top: 20px;
}

.success-modal__text {
  color: #EFEFEF;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  text-align: center;
  margin: 0;
}

.password-reset-success .modal__container,
.account-created .modal__container {
  min-height: 345px;
  padding: 0 25px;
}

.password-reset-success .success-modal__title {
  font-size: 38px;
  text-align: center;
}

.password-reset-success .success-modal__title {
  text-align: center;
}