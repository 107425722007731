.wallet {
  height: 462px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 35px 0 25px;
}
.wallet__img {
  height: 274px;
  width: 283px;
  background: url('../../../../../assets/card.png') no-repeat right;
  background-size: contain;
  margin: 0 0 32px auto;
  position: relative;
  right: -35px;
}
.wallet__cardholder {
  position: absolute;
  left: 48px;
  bottom: 96px;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2.64px;
  line-height: 16px;
  text-transform: uppercase;
}
.wallet__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 14px;
  flex-wrap: wrap;
}

.wallet__row__balance {
  color: #373737;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 2.67px;
  line-height: 38px;
}
