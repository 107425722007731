.map__wrapper {
  width: 200px;
  display: flex;
  align-items: center;
}
.map__wrapper img {
  width: 32px;
  height: 32px;
}
.map__wrapper span {
  margin-left: 10px;
  color: #4B74FF;
  font-size: 14px;
  white-space: nowrap;
}