.card {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  min-height: 246px;
}

.card--pink::after, .card--orange::after, .card--blue::after {
  display: block;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 56px;
}

.card {
  &--pink::after {
    background-color: #F46775;
  }
  &--orange::after {
    background-color: #FEB648;
  }
  &--blue::after {
    background-color: #5A84FB;
  }
}
