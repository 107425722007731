.paginator-info {
  padding-right: 9px;
  font-weight: 400;
}

.paginator-info span {
  font-weight: 700;
}

.paginator-controls__btn, .paginator-controls__btn_disabled {
  height: 32px;
  width: 32px;
  border: 1px solid #C6CBD4;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #FFFFFF;
  outline: none;
}

.paginator-controls__btn:active {
  background-color: #E4EAFF;
}

.paginator-controls__btn_disabled {
  pointer-events: none;
}

.paginator-paging {
  display: flex;
  align-items: center;
}

.paginator-controls {
  display: flex;
  align-items: center;
}

.paginator-controls__arrow-right {
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid #333333;
}

.paginator-controls__arrow-left {
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-right: 3px solid #333333;
}