.profile-stage__bank-details-segment:not(:first-child) {
  margin-top: 15px;
}

.profile-stage__bank-details-title {
  min-height: 50px;
}

.profile-stage__bank-details-title {
  font-size: 20px;
  line-height: 34px;
  color: #323232;
  min-width: 215px;
  margin-right: 50px;
}

.profile-stage__bank-details-segment .profile-stage-inputs-wrapper {
  padding-left: 40px;
}

.profile-stage__bank-details-header-info {
  max-width: 300px;
  font-size: 12px;
  font-weight: 300;
  line-height: 19px;
  margin-bottom: 10px;
}

.profile-stage__bank-details select {
  text-transform: capitalize;
}

.profile-stage-inputs__info span {
  text-transform: capitalize;
}