/* Blue scheme */
/* Dark scheme */
/* Grey scheme */
@font-face {
  font-family: "Corsica Rambler LX";
  font-style: normal;
  font-weight: 300;
  src: url(../assets/fonts/corsica/CorsicaRamblerLX-Light.ttf);
}
@font-face {
  font-family: "Corsica Rambler LX";
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/corsica/CorsicaRamblerLX-Regular.ttf);
}
@font-face {
  font-family: "Corsica Rambler LX";
  font-style: normal;
  font-weight: 500;
  src: url(../assets/fonts/corsica/CorsicaRamblerLX-Medium.ttf);
}
@font-face {
  font-family: "Corsica Rambler LX";
  font-style: normal;
  font-weight: 600;
  src: url(../assets/fonts/corsica/CorsicaRamblerLX-SemiBold.ttf);
}
@font-face {
  font-family: "Corsica Rambler LX";
  font-style: normal;
  font-weight: 700;
  src: url(../assets/fonts/corsica/CorsicaRamblerLX-Bold.ttf);
}
@font-face {
  font-family: "Corsica Rambler LX";
  font-style: italic;
  font-weight: 300;
  src: url(../assets/fonts/corsica/CorsicaRamblerLX-LightItalic.ttf);
}
@font-face {
  font-family: "Corsica Rambler LX";
  font-style: italic;
  font-weight: 400;
  src: url(../assets/fonts/corsica/CorsicaRamblerLX-RegularItalic.ttf);
}
@font-face {
  font-family: "Corsica Rambler LX";
  font-style: italic;
  font-weight: 500;
  src: url(../assets/fonts/corsica/CorsicaRamblerLX-MediumItalic.ttf);
}
@font-face {
  font-family: "Corsica Rambler LX";
  font-style: italic;
  font-weight: 600;
  src: url(../assets/fonts/corsica/CorsicaRamblerLX-SemiBoldItalic.ttf);
}
@font-face {
  font-family: "Corsica Rambler LX";
  font-style: italic;
  font-weight: 700;
  src: url(../assets/fonts/corsica/CorsicaRamblerLX-BoldItalic.ttf);
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1;
  color: #314260;
}
body * {
  box-sizing: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
}

b {
  font-weight: bold;
}

h2 {
  font-family: "Corsica Rambler LX";
  font-size: 36px;
  font-weight: bold;
  line-height: 45px;
}

a {
  color: #274BC8;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.27px;
  line-height: 17px;
}
a:hover {
  color: #4C7ACB;
}
a:active {
  color: #1B317C;
}
a:active:after {
  content: none;
}
a:focus {
  color: #274BC8;
  outline: none;
}
a[disabled] {
  color: #E1E4E8;
}
a:visited {
  color: #274BC8;
}

.visually-hidden, .control > input[type=checkbox] {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
}

small {
  font-size: 12px;
  font-weight: 300;
  line-height: 26px;
  color: #314260;
}

a {
  cursor: pointer;
}

.flex-spacer {
  flex: 1 1 auto;
}

.flex-row, .flex-row-with-space {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex-row-with-space > :not(:last-child) {
  margin-right: 30px;
}

.text-center {
  text-align: center;
}

.flex-1 {
  flex: 1;
}

strong {
  font-weight: 500;
}

button:focus {
  outline: none;
}

.button,
a.button {
  height: 44px;
  padding: 0 40px;
  display: inline-flex;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.5px;
  align-items: center;
  border-radius: 22px;
  border: 0 none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  text-decoration: none;
  /* &:focus {
    position: relative;
    overflow: visible;
  }
  &:focus::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 2px dashed #3F5083;
  } */
}

.button--primary,
a.button--primary {
  color: #fff;
  background-color: #274bc8;
}
.button--primary:hover,
a.button--primary:hover {
  background-color: #5a80dd;
}
.button--primary:active, .button--primary:focus,
a.button--primary:active,
a.button--primary:focus {
  background-color: #1B317C;
}

a.button:focus::after {
  border: none;
}

.button[disabled],
.button:disabled {
  background-color: #E1E4E8;
}

.link-button {
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.27px;
  background: none;
  outline: 0;
  border: 0;
  color: #274BC8;
  fill: #274BC8;
}

.link-button:hover {
  color: #4C7ACB;
  fill: #4C7ACB;
}

.link-button:active {
  color: #1B317C;
  fill: #1B317C;
}

.link-button[disabled],
.link-button:disabled {
  color: #E1E4E8;
}
.link-button[disabled] svg,
.link-button:disabled svg {
  fill: #E1E4E8;
}

.button--icon,
a.button--icon {
  background: transparent;
  padding: 0 10px;
  display: inline-block;
}

.control {
  position: relative;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
}
.control > input,
.control > textarea,
.control > select {
  border-radius: 4px;
  border: 1px solid #D7D7D7;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 0 16px;
  color: #2D313D;
}
.control > input[type=text],
.control > input[type=email],
.control > input[type=password],
.control > input[type=text] {
  width: 100%;
}
.control label {
  margin-left: 10px;
}
.control > input[type=checkbox]:active + label {
  outline: none;
}
.control > input[type=checkbox]:checked + label:after {
  opacity: 1;
  transition: opacity ease-in-out 0.2s;
}
.control > input[type=checkbox] + label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 16px;
  min-width: 16px;
  border: 1px solid #62687F;
  background-color: #F5F5F5;
  cursor: pointer;
}
.control > input[type=checkbox] + label:after {
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 1px;
  border: none;
  background-color: #315EFB;
  opacity: 0;
  transition: opacity ease-in-out 0.2s;
}
.control > input[type=checkbox] ~ span {
  margin-left: 9px;
  font-size: 11px;
  letter-spacing: -0.1px;
  line-height: 15px;
}

.control__error {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 20px;
  font-size: 12px;
  margin: 2px 0 0 0;
  padding: 0;
  color: #E6364B;
  padding-left: 10px;
  line-height: 1;
}

.control--error > input, .control--error textarea, .control--error select {
  border-color: #E6364B;
}

fieldset {
  border: 0 none;
  padding: 0;
  margin: 0;
  box-shadow: none;
}

.control--submit {
  display: flex;
  justify-content: space-around;
  padding-top: 4px;
}