/* Blue scheme */
/* Dark scheme */
/* Grey scheme */
.registration__eye {
  position: absolute;
  right: 16px;
  top: 12px;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
}
.registration__eye .reset-form_eye {
  width: 16px;
  height: 10px;
  fill: #AEAEAE;
}

.reset-password .control--error {
  padding-bottom: 36px;
}

.reset-password .control__error {
  height: 36px;
  align-items: flex-start;
}

.registration__password {
  position: relative;
}

.registration__check a {
  margin-left: 20px;
  user-select: none;
  font-size: 11px;
  line-height: 17px;
}