.signable__canvas-container {
  position: relative;
  display: inline-block;
  padding-right: 15px;
  border: 1px solid #ECEAEA;
}

.signable__clear {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0;
  margin: 0;
  border: 0 none;
  box-shadow: none;
  background: transparent;
}