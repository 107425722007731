.dashboard-modal__pledge {
  background: #3955B7;
  color: white;
  margin: 25px -100px;
  padding: 20px 100px;
}
.dashboard-modal p, .dashboard_modal-caption {
  font-weight: 400;
  font-size: 18px;
  line-height: 34px;
  color: #656D78;
}
.dashboard-modal p strong, .dashboard_modal-caption strong {
  font-weight: 700;
}
.dashboard_modal-caption:first-child {
  padding-top: 27px;
}
.dashboard_modal-caption:last-child {
  padding-bottom: 15px;
}
.dashboard-modal__submit-wrapper {
  display: flex;
  justify-content: center;
}

.confirm-fund {
  display: block;
  margin-bottom: 10px;
  input {
    margin-right: 10px;
  }
}
