.admin-card__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 28px 16px 28px;
  height: 105px;
  color: #3e3e3e;
  font-size: 16px;
  letter-spacing: 1.33px;
  line-height: 19px;
}

.admin-card__number {
  color: #373737;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 2.67px;
  line-height: 38px;
}

.admin-card__progress {
  align-self: flex-end;
  strong {
    font-weight: 500;
  }
}

.admin-card__bottom {
  z-index: 1;
  position: absolute;
  bottom: 0;
  height: 55px;
  width: calc(100% - 56px);
  justify-content: space-between;
  align-items: center;
  display: flex;
  left: 28px;
  color: white;
}
.admin-card__bottom-caption {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1.17px;
}
.admin-card__bottom-value {
  font-size: 16px;
  letter-spacing: 1.33px;
}
.admin-card__progress svg {
  fill: #DE5F38;
  width: 15px;
  height: 15px;
}
.admin-card__progress_none {
  display: none;
}

.admin-card__progress_up svg{
  transform: rotate(180deg)
}

.admin-card__progress_up #Dashboard-Filter {
  fill: #56BC3F;
}
.admin-card__progress_up svg {
  fill: #56BC3F;
}
.admin-card #hand use {
  fill: #D1DDFF;
}
.admin-card #hand use+g {
  fill: #5A84FB;
}
.admin-card #money use {
  fill: #FFDFE2;
}
.admin-card #money use+g {
  fill: #FF4C5D;
}
