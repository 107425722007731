.card-title--wrapper {
  font-family: 'Roboto';
  display: flex;
  flex-direction: column;
  color: #3e3e3e;
  font-size: 16px;
}

.card-title__subtitle {
  letter-spacing: 1.33px;
  line-height: 19px;
  margin: 0 0 6px 0;
}

.card-title__title {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.33px;
  line-height: 19px;
  text-transform: uppercase;
  margin: 0;
}
