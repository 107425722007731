
.user-list__wrapper {
  tr {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  td, th {
    display: block;
    flex: 5;
    min-width: 100px;
    padding: 10px;
    display: flex;
    align-items: center;
    .button {
      text-align: left;
    }
  }
  .small-width {
    flex: 1;
  }
  .medium-width {
    flex: 2;
  }
  .large-width {
    flex: 6;
  }
  .break-words {
    word-break: break-all;
  }
}
.reset-questions-modal {
  color: white;
  font-size: 23px;
  margin-top: 50px;
  text-align: center;
}

.admin-userlist__wrapper .modal__container {
  padding: 15px;
  min-height: 150px;
}