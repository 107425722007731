.request-pass-restore-modal__content > p {
  margin-top: 25px;
  color: #656D78;
  font-size: 18px;
  line-height: 34px;
}
.request-pass-restore-modal__content > p strong {
  font-weight: bold;
}

.request-pass-restore-modal__success {
  color: #274bc8;
  text-align: center;
}

.reset-success {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}