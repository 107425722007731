.profile-stage-inputs-wrapper {
  display: flex;
  align-items: center;
}

.profile-stage-inputs__title {
  min-width: 161px;
  padding-right: 30px;
  text-align: right;
  padding-bottom: 20px;
  color: #383838;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.profile-stage-inputs__input {
  position: relative;
  display: block;
}

.profile-stage__inputs-wrapper .fileupload-container {
  flex-direction: column;
}

.profile-stage__inputs-wrapper .fileupload-info {
  margin-left: 0;
  margin-top: 13px;
}

.profile__phone-number {
  display: flex;
  width: 345px;
  justify-content: space-between;
}

.profile__phone-select select {
  width: 80px !important;
}

.profile__phone-select .svg_caret {
  left: 50px;
  top: 17px;
}

.profile__phone-number input {
  width: 260px !important;
}

.profile-stage__bank-form {
  margin-left: 130px;
}

.control--error .profile__phone-number input {
  border-color: #E6364B;
}

.profile_success-message {
  color: #56BC3F;
  padding-right: 40px;
  text-align: right;
}

.confirm-phone-number-modal h3 {
  margin-top: 60px;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
}