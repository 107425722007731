.list-headers__wrapper {
  font-family: "Roboto";
}

.list-headers__wrapper .control {
  padding-bottom: 12px;
}

.list-headers__wrapper .control > input[type=checkbox] ~ span {
  line-height: 24px;
}

.list-headers__arrow-right {
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid #333333;
}

.list-headers__arrow-left {
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-right: 3px solid #333333;
}

.list-headers__trigger-indicator_opened {
  transform: translateY(4px);
  width: 12px;
}

.list-headers__trigger-indicator {
  width: 12px;
  transform: rotate(180deg) translateY(4px);
}

.list-headers__header1 {
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}

.list-headers__header1-caption {
  font-family: Corsica Rambler LX;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #333333;
}

.list-headers__header1-search {
  width: 160px;
  height: 44px;
  position: relative;
}
.list-headers__header1-search .img_search {
  width: 17.5px;
  height: 17.5px;
  fill: #4B74FF;
  position: absolute;
  top: 13px;
  left: 30px;
}

.list-headers__header1-input {
  width: 100%;
  height: 100%;
  border: 1px solid #4B74FF;
  border-radius: 24px;
  padding: 15px 40px 15px 55px;
  outline: 0;
  color: #4B74FF;
  transition: all linear 0.125s;
}

.list-headers__header1-input:valid, .list-headers__header1-input:focus {
  padding: 15px;
  width: 110%;
  transform: translateX(-10%);
}

.list-headers__header1-input:valid ~ .img_search,
.list-headers__header1-input:focus ~ .img_search {
  display: none;
}

.list-headers__header1-input::placeholder {
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.5px;
  color: #4B74FF;
}

.list-headers__header1-input:focus::placeholder {
  color: transparent;
}

.list-headers__header2 {
  height: 62px;
  display: flex;
  align-items: center;
  padding: 0 18px 0 78px;
  justify-content: space-between;
}

.list-headers__selector-dropdown {
  position: absolute;
}

.list-headers__trigger {
  width: 94px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #4B74FF;
  border-radius: 2px;
  font-size: 14px;
  line-height: 24px;
  color: #4B74FF;
  padding: 4px 15px 4px 20px;
  cursor: pointer;
  background: none;
  outline: 0;
}

.list-headers__selector-dropdown {
  z-index: -1;
  opacity: 0;
  transform: translateY(-5px);
  transition: transform linear 0.125s;
  padding: 20px 20px 0 20px;
  cursor: pointer;
}

.list-headers__selector-dropdown_opened {
  z-index: 2;
  opacity: 1;
  width: 236px;
  height: auto;
  border-radius: 4px;
  background: white;
  transform: translateY(0);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
}

.list-headers__selector-dropdown::after {
  position: absolute;
  content: "";
  top: 0;
  left: -75px;
  width: calc(100% + 150px);
  height: calc(100% + 150px);
  z-index: -1;
}

.list-headers__status-selector-apply {
  margin-left: auto;
  background: none;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
  font-weight: 400;
  text-decoration: underline;
  display: block;
  cursor: pointer;
}

.list-headers__header2-info {
  padding-right: 9px;
  font-weight: 400;
}

.list-headers__header2-info span {
  font-weight: 700;
}

.list-headers__header2-control, .list-headers__header2-control_disabled {
  height: 32px;
  width: 32px;
  border: 1px solid #C6CBD4;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.list-headers__header2-paging {
  display: flex;
  align-items: center;
}

.list-headers__header2-control_disabled {
  pointer-events: none;
}

.list-headers__header2-controls {
  display: flex;
  align-items: center;
}