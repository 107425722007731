.history-table {
  font-size: 14px;
  text-align: left;
  border-spacing: unset;
  p {
    margin: 0;
  }
}

.history-table tr th {
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.history-table {
  tr th:first-child, tr td:first-child  {
    padding-left: 25px;
  }
  tr td:last-child {
    padding-right: 35px;
  }
}
.history-table tr {
    height: 44px;
}
.history-table tr td {
  color: #333333;
}
.history-table {
  .amount {
    font-weight: 500;
  }
  .tsxId {
    font-weight: 300;
  }
  .status {
    font-weight: bold;
    text-align: right;
  }
  .status.orange {
    color: #DE5F38;;
  }
  .status.green {
    color: #56BC3F;
  }
  .status.pink {
    color: #F46775;
  }
  .date, .type {
    color: #989898;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
  }
}
