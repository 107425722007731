/* Blue scheme */
/* Dark scheme */
/* Grey scheme */
.footer-wrapper {
  width: 100%;
}

.footer-wrapper.has-pre-footer {
  margin-top: 20px;
  flex-grow: 0;
}

.footer {
  width: 100%;
  padding: 35px 0 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #343B4C;
  color: #fff;
}
.footer h6 {
  font-size: 24px;
  margin: 0;
}

.footer__title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.footer__title-container svg {
  margin-left: 10px;
  height: 20px;
  width: auto;
}

.footer__title {
  margin-right: 10px;
  font-size: 10px;
}

.footer__all-rights {
  margin: 15px 0 0;
  font-size: 16px;
}

.footer__all-rights {
  color: #fff;
}

.prefooter {
  background-color: #343B4C;
  padding: 13px 0;
  margin-top: auto;
  flex: 0;
  position: sticky;
  bottom: 0;
  flex-grow: 0 !important;
}

.prefooter__text {
  font-size: 12px;
  margin: 0 auto;
  max-width: 950px;
  line-height: 17px;
  color: #FFFFFF;
  text-align: center;
}
.prefooter__text > a {
  font-size: 12px;
  color: #fff;
  text-decoration: underline;
}