.pledge-esignature-step {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pledge-esignature-step iframe {
  flex-grow: 1;
}

.pledge-esignature__download-agreement {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;
  border-radius: 11px;
  background-color: #F7F7F7;
  margin: 15px 0;
  padding: 0 30px;
  text-decoration: none;
}
.pledge-esignature__download-agreement > div {
  display: flex;
  align-items: center;
}
.pledge-esignature__download-agreement > div span {
  color: #262626;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.pledge-esignature__download-agreement > div svg {
  margin-right: 20px;
}