.dashboard-selector {
  margin: 15px 0 30px 45px;
  .list-headers__trigger {
    width: unset;
    background: white;
  }
  .list-headers__trigger-indicator, .list-headers__trigger-indicator_opened {
    margin-left: 10px;
  }
  .list-headers__selector-dropdown_opened {
    width: 200px;
  }
  .list-headers__dropdown-item:hover {
    font-weight: 700;
  }
}