.user-profile__caption {
    font-family: 'Corsica Rambler LX';
    font-size: 32px;
    line-height: 48px;
    color: #333333;
    margin-bottom: 9px;
    padding-left: 15px;
}

.user-profile-page {
    position: relative;
}

.user-profile__notification,
.user-profile__notification_error {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 4px;
    background-color: #4B74FF;
    color: #fff;
    padding: 10px 20px;
}

.user-profile__notification_error {
    background-color: #f46775;
}