.error-modal .svg_cancel {
  fill: #BED6F3;
}

.error-modal__sad-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.error-modal__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 315px;
}

.error-modal__title {
  padding: 28px 0 22px 0;
  font-family: Corsica Rambler LX;
  font-weight: 600;
  font-size: 52px;
  line-height: 53px;
  text-align: center;
  color: white;
}

.error-modal__content {
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  color: #EFEFEF;
}