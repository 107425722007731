.signable__upload-container {
  width: 250px;
  height: 114px;
}
.signable__upload-container .dropzone {
  text-align: center;
  height: 100%;
}
.signable__upload-container .fileupload-button {
  width: 100%;
  max-width: 100%;
  min-width: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
}

.signable__upload-image {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #ECEAEA;
}
.signable__upload-image img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}
.signable__upload-image .svg_close {
  position: absolute;
  top: 5px;
  right: 5px;
}