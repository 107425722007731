.admin-payments {
  margin-top: 35px;
}
.admin-payments__header-1 {
  font-size: 26px;
  line-height: 48px;
  color: #333333;
  padding: 0 15px 12px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  margin-bottom: 15px;
}
.admin-payments__header-1-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.admin-payments__error {
  text-align: center;
  font-size: 12px;
  color: #E6364B;
  line-height: 1;
  max-width: 200px;
}
.admin-payments__main-content {
  padding: 0 15px 75px;
}

.admin-payments__header-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
}

.admin-payments__tabs {
  padding-left: 0;
  margin-bottom: 35px;
  li {
    display: inline-block;
    text-transform: uppercase;
    padding: 0 84px 12px;
    border-bottom: 4px solid transparent;
    transition: 0.3s ease;
    a {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: rgba(51, 51, 51, 0.5);
      list-style: none;
      text-decoration: none;
    }
  }
  li.active {
    border-color: #333333;
    a {
      color: #333333;
    }
  }
}
