.pledge-statement-step {
  display: flex;
  flex-direction: column;
}

.pledge-statement-step__statement {
  flex-grow: 1;
  padding: 0 30px;
  overflow-x: auto;
  background-color: #F7F7F7;
  margin-bottom: 40px;
}
.pledge-statement-step__statement p {
  color: #414141;
  font-size: 14px;
  font-weight: 300;
  line-height: 23px;
  margin: 25px 0;
}
.pledge-statement-step__statement p strong {
  font-weight: 600;
}