.pledgeproc-stage-wrapper--success {
  display: flex;
  width: auto;
  margin-left: -60px;
  margin-right: -50px;
  margin-bottom: 10px;
}

.pledgeproc__finished {
  background-image: url(../../../assets/pledge-right.jpg);
  background-size: contain;
  padding: 0 350px 0 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.pledgeproc__finished-congrats {
  font-family: Corsica Rambler LX;
  font-size: 42px;
  line-height: 34px;
  color: white;
  padding: 30px 0;
  text-align: center;
}

.pledgeproc__finished-info {
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
  color: #EFEFEF;
  text-align: center;
}

.pledgeproc__finished-info strong {
  font-weight: 700;
}

.pledgeproc__finished + .kyc-buttons .kyc__back-button {
  padding-left: 70px;
}

.pledgeproc__finished-confetti {
  text-align: center;
}