.pledged-status__bar {
  position: relative;
  height: 3px;
  border-radius: 3px;
  overflow: hidden;
  background-color: #e5e5e5;
}
.pledged-status__bar > div {
  position: absolute;
  border-radius: 3px;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #315EFB;
}

.pledged-status__numbers {
  display: flex;
  justify-content: space-between;
  color: #262626;
  font-family: Roboto;
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 8px;
}