.create-project {
  padding: 40px 80px;
  max-width: 1260px;
}
.create-project form {
  width: 100%;
}

.create-project__section {
  width: 100%;
  display: flex;
  margin-bottom: 100px;
}
.create-project__section.main-info {
  flex-wrap: wrap;
  justify-content: space-between;
}
.create-project__section.with-bar {
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  position: relative;
}
.create-project__section.with-bar:before {
  content: "";
  position: absolute;
  top: 25px;
  right: 0;
  height: 2px;
  left: 10px;
  background-color: #D8D8D8;
  z-index: 1;
}
.create-project__section.with-bar .create-project__fieldset-title {
  width: auto;
  position: relative;
  background-color: #fff;
  padding-right: 16px;
  align-self: flex-start;
  z-index: 2;
}
.create-project__section .flex-row.bar_margin {
  margin-bottom: 15px;
}

.create-project__fieldset-title {
  width: 100%;
  color: #333333;
  font-family: "Corsica Rambler LX";
  font-size: 25px;
  font-weight: bold;
  line-height: 48px;
  margin-bottom: 30px;
}

.create-project__allow-long-term-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
}
.create-project__allow-long-term-wrapper .create-project__fieldset-title {
  margin-bottom: 0;
}
.create-project__allow-long-term-wrapper > div {
  flex: 1 0 0;
}

.create-project__wrapper {
  width: auto;
  display: flex;
  align-items: center;
}
.create-project__wrapper .create-project__title {
  padding-bottom: 20px;
}
.create-project__wrapper.two-cols {
  width: 45%;
  justify-content: space-between;
}
.create-project__wrapper.two-cols .control {
  width: calc(100% - 145px);
}
@media screen and (max-width: 900px) {
  .create-project__wrapper.two-cols {
    width: 100%;
    align-items: flex-start;
  }
}
.create-project__wrapper.period {
  width: 100%;
  justify-content: flex-start;
}
.create-project__wrapper.period .create-project__title {
  min-width: auto;
}
.create-project__wrapper.period > span {
  margin-left: 20px;
  margin-bottom: 20px;
}
.create-project__wrapper.textarea {
  width: 60%;
  flex-direction: column;
  align-items: start;
  margin-top: 20px;
}
.create-project__wrapper.funding .create-project__title {
  margin: 0 14px 0 38px;
}
.create-project__wrapper.funding .create-project__number-control input {
  width: 120px;
}
.create-project__wrapper.funding .control {
  padding-bottom: 40px;
}
.create-project__wrapper.funding .control__error {
  bottom: 10px;
}

.create-project__wrapper--strategy {
  display: block;
}
.create-project__wrapper--strategy .create-project__title {
  font-weight: 900;
  margin-left: 20px;
}
.create-project__wrapper--strategy input {
  max-width: 127px;
}
.create-project__wrapper--strategy .control--input {
  display: flex;
  margin-top: 10px;
}
.create-project__wrapper--strategy .control--input label {
  margin-right: 36px;
  margin-left: 37px;
  font-weight: 500;
  width: 100px;
  padding-bottom: 20px;
}

.create-project__separator {
  margin: 0 10px 20px 10px;
  display: block;
  box-sizing: border-box;
  height: 1px;
  width: 29px;
  border: 1px solid #4B74FF;
}

.create-project__funding-container {
  padding-top: 56px;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 1100px) {
  .create-project__funding-container {
    align-items: flex-end;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 900px) {
  .create-project__funding-container {
    width: 100%;
    align-items: flex-start;
  }
}

.create-project__date-control.control input {
  padding: 12px 10px;
  border: 1px solid rgba(0, 0, 0, 0.17);
  border-radius: 5px;
  max-width: 124px;
  height: 40px;
}
.create-project__date-control.control input:focus {
  padding: 12px 9px;
  outline: none;
}
.create-project__date-control.control input:focus + .svg_calendar {
  fill: #315EFB;
}
.create-project__date-control.control .svg_calendar {
  position: absolute;
  right: 10px;
  top: 13px;
  height: 14px;
  width: 13px;
}
.create-project__date-control.control .svg_calendar.filled {
  fill: #315EFB;
}
.create-project__date-control.control.control--error input {
  border-color: #E6364B;
}

.create-project__title {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  width: 120px;
  overflow: hidden;
}

.create-project__title.date {
  width: auto;
  margin-right: 20px;
}

.create-project__textarea-control.control {
  width: 100%;
}
.create-project__textarea-control.control textarea {
  padding: 16px;
  width: 100%;
  min-height: 269px;
  resize: none;
}

.create-project__input-control.control input {
  background: none;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  outline: none;
  appearance: none;
  display: block;
  padding: 0 16px;
}
.create-project__input-control.control input:focus {
  padding: 0 15px;
  outline: none;
}
.create-project__input-control.control.control--error input {
  border-color: #E6364B;
}

.create-project__select-control.control select {
  background: none;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  outline: none;
  appearance: none;
  display: block;
  padding: 0 16px;
  cursor: pointer;
}
.create-project__select-control.control select:focus {
  padding: 0 15px;
  outline: none;
}
.create-project__select-control.control select:focus + .img_caret {
  transform: rotate(180deg);
}
.create-project__select-control.control .img_caret {
  position: absolute;
  right: 15px;
  pointer-events: fill;
}

.create-project__checkbox-control .create-project__title {
  margin-left: 20px;
  min-width: 106px;
  margin-right: 50px;
  padding-bottom: 0;
}
.create-project__checkbox-control .control__error {
  bottom: 16px;
  left: 32px;
}

.create-project__add-more {
  align-self: flex-start;
  background-color: #4B74FF;
}
.create-project__add-more:after {
  content: "+";
  font-size: 24px;
  font-weight: normal;
  margin-left: 10px;
  display: inline-block;
  margin-top: -2px;
}

.control .create-project__title-input {
  padding: 5px;
  height: auto;
  background-color: #fff;
  border: none;
  margin-left: 20px;
  margin-right: 50px;
  border-radius: 0;
}
.control .create-project__title-input:focus {
  background-color: #fff;
  border: none;
}
.control .create-project__title-input:placeholder-shown {
  background-color: #C2CEF7;
}
.control .create-project__title-input::placeholder {
  color: #fff;
}

.create-project__inputs-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  margin-bottom: 35px;
}

.create-project__remove-field {
  background-color: transparent;
  border: none;
  margin-left: 40px;
  padding: 0;
  cursor: pointer;
  height: 20px;
}
.create-project__remove-field:focus {
  outline: none;
}
.create-project__remove-field img {
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.create-project__submit {
  text-align: center;
}

.create-project__images-text {
  margin-bottom: 25px;
}
.create-project__images-text p {
  margin-top: 0;
}

.create-project__investment-strategy-error {
  position: absolute;
  left: 0;
  top: 50px;
  font-size: 12px;
  color: #E6364B;
  line-height: 1;
}