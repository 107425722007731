.user-list__wrapper {
  font-family: "Roboto";
  padding: 0 10px;
}

.user-list__wrapper table {
  width: 100%;
  border-collapse: collapse;
}
.user-list__wrapper .small-width {
  width: 65px;
  white-space: nowrap;
}
.user-list__wrapper td, .user-list__wrapper th {
  text-align: left;
  height: 65px;
  color: #333333;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
}
.user-list__wrapper td.right, .user-list__wrapper th.right {
  text-align: right;
}
.user-list__wrapper tbody tr:nth-of-type(2n+1) {
  background-color: #f2f3f5;
}
.user-list__wrapper tr td:first-child, .user-list__wrapper tr th:first-child {
  padding: 0 20px;
}
.user-list__wrapper th {
  opacity: 0.5;
  color: #333333;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}
.user-list__wrapper tbody tr:hover {
  background-color: #f2f3f5;
}
.user-list__wrapper tbody tr:hover td:first-child {
  position: relative;
}
.user-list__wrapper tbody tr:hover td:first-child::before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background-color: #274bc8;
}

.user-list__menu-cell {
  position: relative;
}

.user-list__user-settings {
  position: absolute;
  top: 60%;
  right: 20px;
  z-index: 1000;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
}
.user-list__user-settings > a, .user-list__user-settings > button {
  text-decoration: none;
  display: flex;
  padding: 16px 20px;
  line-height: 24px;
  background-color: transparent;
  align-items: center;
  justify-content: flex-start;
  border: 0;
  width: 100%;
  cursor: pointer;
  color: #333333 !important;
}
.user-list__user-settings > a:hover, .user-list__user-settings > button:hover {
  background-color: #F1F1F1;
}
.user-list__user-settings > a img, .user-list__user-settings > button img {
  width: 14px;
  height: 12px;
  margin-right: 10px;
}

.svg_down {
  width: 24px;
  height: 24px;
}

.svg_more {
  width: 24px;
  height: 24px;
}

.user-list__wrapper .users-list__th-sort {
  display: flex;
  align-items: center;
  color: #4B74FF;
  opacity: 1;
  cursor: pointer;
}

.user-list__rotate-icon {
  transform: rotate(180deg);
}