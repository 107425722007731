.project-details-wrapper {
  font-family: "Roboto";
  background-color: #DDE3EC;
}

.project-details-wrapper .slick-dots {
  bottom: 14px;
}

.project-details-wrapper__header {
  height: 83px;
  color: #333333;
  padding-left: 15px;
  background: white;
}

.project-details-wrapper__header-link {
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.2px;
  display: inline-block;
}
.project-details-wrapper__header-link span {
  display: flex;
  align-items: center;
}

.project-details-wrapper__header-caption {
  font-family: Corsica Rambler LX;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  padding-top: 15px;
}

.project-details {
  padding: 20px 0 100px 0;
  max-width: 1140px;
  margin: 0 auto;
}

.project-details__header {
  max-width: 703px;
  margin: 0 auto;
  margin-bottom: 52px;
}

.project-details__header-main {
  font-family: Corsica Rambler LX;
  font-weight: 700;
  font-size: 48px;
  line-height: 77px;
  color: #314260;
  padding-top: 17px;
  text-align: center;
}

.project-details__header-secondary {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #262626;
  text-align: center;
  word-wrap: break-word;
}

.project-details__main {
  display: flex;
  padding-bottom: 100px;
}

.project-details__main-left, .project-details__main-right {
  width: 50%;
}

.project-details__main-left {
  padding-left: 20px;
  padding-right: 30px;
}

.project-details__project-status {
  font-weight: 400;
  font-size: 21px;
  line-height: 24px;
  color: #282828;
  margin-bottom: 20px;
}

.project-details__project-status span {
  font-weight: 700;
  color: #274BC8;
  padding-bottom: 15px;
}

.project-details__main-text {
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.4px;
  color: #262626;
  word-wrap: break-word;
}

.project-details__slider {
  width: 540px;
  height: 319px;
  background-position: 50%;
  background-size: cover;
  margin-bottom: 10px;
}
.project-details__slider.empty-slider {
  background-image: url("../assets/no-image-available.jpg");
}
.project-details__slider .project-details__inner-slider {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 0;
}

.project-details__main-right {
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-details__finance-info {
  width: 380px;
}

.project-details__pledged-text {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
}

.project-details__indicator-wrapper {
  height: 5px;
  border-radius: 3.5px;
  background: white;
  overflow: hidden;
}

.project-details__pledged-indicator {
  height: 5px;
  background: #315EFB;
  border-radius: 3.5px 0 0 3.5px;
  margin-bottom: 12px;
}

.project-details__funds {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}

.project-details__funds-left, .project-details__funds-right {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
}

.project-details__funds-left span {
  color: #314260;
  font-family: "Corsica Rambler LX";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 35px;
}

.project-details__funds-left span::after {
  content: ", ";
}

.project-details__funds-left span:last-child::after {
  content: "";
}

.project-details__project-buttons {
  height: 133px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  line-height: 13.5px;
  letter-spacing: 0.5px;
}
.project-details__project-buttons > button {
  min-width: 270px;
  justify-content: center;
}

.project-details__pledge-button {
  background: #4B74FF;
  color: white;
}

.project-details__follow-button {
  background: #489CB7;
  color: white;
}

.project-details__calculations {
  margin-top: 50px;
  width: 380px;
  height: 438px;
  background: #FBFBFB;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.project-details__calculations-white {
  flex: 6;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.project-details__calculations-blue {
  background: #BED6F3;
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-details__calculations-blue .button:not(:disabled) {
  background: #4B74FF;
  color: white;
  margin-bottom: 30px;
}

.project-details__calculations-top {
  padding-top: 50px;
}

.project-details__calculations-caption {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
  text-align: center;
}

.project-details__calculations-result {
  font-weight: 600;
  font-family: Corsica Rambler LX;
  font-size: 48px;
  line-height: 61px;
  color: #314260;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.project-details__calculations-month, .project-details__calculations-rate {
  padding-bottom: 13px;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
}

.project-details__calculations-month span {
  font-weight: 700;
}

.project-details__calculations-rate span {
  font-weight: 400;
}

.project-details__calculations-initial {
  padding-top: 30px;
  padding-bottom: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.project-details__calculations-error {
  bottom: 15px;
  left: 0;
  width: 100%;
  text-align: center;
  color: red;
  position: absolute;
}

.project-details__calculations-input_error {
  border: 2px solid red !important;
}

.project-details__calculations-initial span {
  font-size: 400;
  font-size: 16px;
  line-height: 19px;
  color: #282828;
  padding-right: 19px;
}

.project-details__calculations-input {
  border-radius: 4px;
  border: 2px solid #5874C9;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #2D313D;
  padding: 8px 16px;
  max-width: 97px;
  outline: 0;
}

.project-details__downloads {
  background: #314260;
  border: 1px solid #314260;
  padding: 73px 205px 100px 205px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-details__downloads-caption {
  font-family: Corsica Rambler LX;
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  color: #FAF9F9;
  width: 100%;
  text-align: center;
  padding-bottom: 50px;
}

.project-details__downloads-files {
  height: 70px;
  border-radius: 11px;
  background: #F5F5F5;
  border: 1px solid #E4E4E4;
  display: flex;
  align-items: center;
  padding: 0 31px;
  margin-bottom: 15px;
  justify-content: space-between;
  width: 100%;
}

.project-details__downloads-left {
  display: flex;
}

.project-details__downloads-fileinfo {
  padding-left: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-details__downloads-filename {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
}

.project-details__downloads-filesize {
  font-weight: 400;
  font-size: 8px;
  max-width: 590px;
  word-break: break-all;
  line-height: 9px;
  color: #262626;
}

.svg_download {
  width: 23px;
  height: 23px;
  fill: #314260;
  cursor: pointer;
}

.project-details__downloads button {
  background: #4B74FF;
  color: white;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.5px;
  margin-top: 24px;
}

.project-details__map-section {
  padding: 72px 15px 100px 15px;
}

.project-details__map-title {
  text-align: center;
  font-family: Corsica Rambler LX;
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  color: #314260;
  margin-bottom: 50px;
}

.project-details__ask-button {
  cursor: pointer;
  outline: 0;
  border: 0;
  background: transparent;
  color: #262626;
  text-decoration: underline;
}

.project-details-wrapper .slick-dots li button:before {
  opacity: 1;
  color: #B0B0B0;
  text-shadow: 1px 1px 3px black, 0 0 7px #315EFB;
  font-size: 12px;
}

.project-details-wrapper .slick-dots li.slick-active button:before,
.project-details-wrapper .slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
  color: white;
}

.project-details__calculations .list-headers__trigger {
  box-sizing: border-box;
  height: 40px;
  width: 242px;
  border: 1px solid #D7D7D7;
  border-radius: 4px;
  background-color: #FFFFFF;
  margin-top: 30px;
  color: #AEAEAE;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 15px;
  padding: 10px;
}
.project-details__calculations .list-headers__trigger-indicator, .project-details__calculations .list-headers__trigger-indicator_opened {
  right: 0;
  position: relative;
  width: 24px;
  height: 14px;
  transform: translateY(3px) translateX(10px);
}
.project-details__calculations .list-headers__trigger-indicator .svg_caret, .project-details__calculations .list-headers__trigger-indicator_opened .svg_caret {
  width: 24px;
  height: 14px;
  right: 0;
}
.project-details__calculations .list-headers__trigger-indicator_opened {
  transform: translateY(-3px) rotate(180deg);
}
.project-details__calculations .list-headers__trigger-indicator_opened .svg_caret {
  fill: #305DF7;
}
.project-details__calculations .list-headers__status-selector_opened .list-headers__trigger {
  border: 1px solid #305DF7;
}
.project-details__calculations .list-headers__selector-dropdown, .project-details__calculations .list-headers__selector-dropdown_opened {
  padding: 0;
  width: 242px;
  height: auto;
}
.project-details__calculations .list-headers__dropdown-item {
  height: 38px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  color: #262626;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 26px;
}
.project-details__calculations .list-headers__dropdown-item:hover {
  cursor: pointer;
  background-color: #F3F5FB;
}

.project-details__calculations-blue_chosen .list-headers__trigger {
  color: #262626;
}

.project-details__terms-caption {
  color: #314260;
  font-family: "Corsica Rambler LX";
  font-size: 33px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 41px;
  padding-bottom: 18px;
}

.project-details__terms-wrapper:nth-child(2) {
  margin-top: 50px;
}
.project-details__terms-wrapper:nth-child(2) .project-details__pledged-indicator {
  background-color: #489CB7;
}

.project-details__calculations .button {
  margin-bottom: 30px;
}