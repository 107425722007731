.contact-us-form__composition {
  background-color: #fff;
  .message__success {
    text-align: center;
    line-height: 25px;
  }
  p {
    font-size: 18px;
    line-height: 34px;
  }
  input {
    width: 100%;
  }
  select, textarea {
    width: 100%;
  }
  .control > textarea {
    height: 90px;
    padding-top: 15px;
  }
  a {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.11px;
  }
  select {
    appearance: none;
  }
  .button {
    margin-top: 10px;
  }
  .control > p {
    margin: 0 0 0 9px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.11px;
    color: #656D78;

  }
}
.contact-us-checkbox__position.control {
  align-items: normal;
  margin-bottom: 13px;
  padding-bottom: 0;
  // error message position fix
  input[type=checkbox] {
    width: 9px !important;
    height: 9px !important;
  }
}
.subscribe-form__interest {
  position: relative;
  &:after {
    position: absolute;
    content: '';
    top: 12px;
    right: 15px;
    width: 8px;
    height: 8px;
    border-left: 2px solid #A6A6A6;
    border-top: 2px solid #A6A6A6;
    transform: rotate(-135deg);
  }
}
.contact-us__form {
  text-align: center;
  .message {
    font-size: 16px;
  }
}