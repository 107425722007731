.pledge-conditions-step {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  max-height: calc(100% - 50px);
}
.pledge-conditions-step iframe {
  width: 100%;
}
.pledge-conditions-step + .flex-spacer {
  flex-grow: 0;
}

.pledge-conditions-step__conditions {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #F7F7F7;
}
.pledge-conditions-step__conditions iframe {
  flex-grow: 1;
}

.terms-conditions-link {
  text-decoration: none;
}
.terms-conditions-link:hover {
  text-decoration: underline;
}