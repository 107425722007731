.admin-project-list {
  padding: 0 10px;
  .list-headers__selector-dropdown_opened {
    height: auto;
  }
  tr {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  td, th {
    display: block;
    flex: 5;
    min-width: 30px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .small-width {
    flex: 1;
  }
  .medium-width {
    flex: 3;
  }
  .large-width {
    flex: 6;
  }
  .break-words {
    word-break: break-word;
  }
  .menu-cell {
    max-width: 40px;
  }
}
.admin-project-list__header-joined {
  min-width: 400px;
  display: flex;
  justify-content: space-between;
}
.admin-project-list__header .projects-list__header2 {
  padding: 0 45px;
}
.admin-project-list__header .project-list__selector-dropdown_opened {
  height: 295px;
}


.admin-project-list__header {

  h1 {
    color: #333333;
    font-family: "Corsica Rambler LX";
    font-size: 32px;
    font-weight: bold;
    line-height: 48px;
  }
}

.admin-project-list__wrapper {
  table {
    width: 100%;
    border-collapse: collapse;
  }
  .small-width {
    width: 100px;
    white-space: nowrap;
  }
  td, th {
    text-align: left;
    padding: 10px;
    min-height: 65px;
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    &.right {
      text-align: right;
      .project-list__th-sort {
        justify-content: flex-end;
      }
    }
  }
  tbody tr:nth-of-type(2n+1) {
    background-color: #f2f3f5;
  }

  tr td:first-child, tr th:first-child {
    padding: 0 20px;
  }

  th {
    opacity: 0.5;
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  tbody tr:hover {
    background-color: #f2f3f5;
    td:first-child {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        height: 100%;
        width: 3px;
        background-color: #274bc8;
      }
    }
  }
}

.projects-list__wrapper .control {
  padding-bottom: 12px;
}
.projects-list__wrapper .control > input[type="checkbox"] ~ span {
  line-height: 24px;
}

.project-list__menu-cell {
  position: relative;
  max-width: 40px;
}

.project-list__user-settings {
  position: absolute;
  top: 60%;
  right: 40px;
  z-index: 1000;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.16);

  > a, > button {
    text-decoration: none;
    display: flex;
    padding: 16px 20px;
    line-height: 24px;
    background-color: transparent;
    align-items: center;
    border: 0;
    cursor: pointer;
    color: #333333 !important;

    &:hover {
      background-color: #F1F1F1;
    }

    img {
      width: 14px;
      height: 12px;
      margin-right: 10px;
    }
  }
}
.project-list__th-sort {
  display: flex;
  cursor: pointer;
}
.admin-project-list__active-header {
  color: #4B74FF;
}
.strategy-list-item:not(:last-child)::after {
  content: ', ';
}