.pledge-option {
  margin-top: 30px;
}

.pledge-option > input {
  display: none;
}

.pledge-option > label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 100px;
  border: 1px solid #DDE3E8;
  border-radius: 3px;
  padding: 1px 41px;
}

.pledge-option > input:checked + label {
  border: 2px solid #274BC8;
  padding: 0px 40px;
}

.pledge-option__title {
  color: #252525;
  font-size: 16px;
  line-height: 19px;
  flex: 0 0 150px;
  margin-right: 20px;
}

.pledge-option__description {
  color: #788995;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  flex: 0 0 250px;
  margin-left: 20px;
}

.pledge-option__value {
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
  color: #788995;
}
.pledge-option__value strong {
  font-weight: 500;
  color: #1B2125;
}