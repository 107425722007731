.table {
  font-size: 14px;
  text-align: left;
  border-spacing: unset;
}

.table tr th {
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}
.table.orange tr th {
  color: #EE8427;
}
.table.violet tr th {
  color: #4B74FF;
}
.table.blue-green tr th {
  color: #02CCCF;
}
.table.red tr th {
  color: #E54154;
}
.table {
  tr th:first-child, tr td:first-child {
    padding-left: 25px;
  }
}
.table tr {
    height: 44px;
}
.table tr td {
  color: #333333;

  &:last-child {
    font-weight: lighter;
  }
}
.table.row-grey tbody tr:nth-child(2n) {
    background-color: #F2F3F5;
}
.table.row-violet tbody tr:nth-child(2n) {
    background-color: #D4DEFF;
}

