.project-card {
  border-radius: 11px;
  background-color: #fefefe;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.project-card__image {
  height: 200px;
  width: 100%;
  background-image: url(../assets/no-image-available.jpg);
  background-position: 0;
  background-size: cover;
}
.project-card__image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.project-card__info {
  padding: 35px 15px 25px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.project-card__info .pledged-status {
  margin-bottom: 20px;
}

.project-card__title-wrapper {
  display: flex;
  justify-content: space-between;
}

.project-card__title-wrapper > div {
  width: 45%;
}

.project-card__title {
  color: #262626;
  font-family: "Corsica Rambler LX";
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.project-card__tag {
  opacity: 0.41;
  color: #262626;
  font-size: 13px;
  line-height: 15px;
}

.project-card__description-wrapper {
  flex-grow: 1;
}

.project-card__description {
  color: #262626;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.4px;
  line-height: 26px;
  height: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.project-card__strategy {
  margin: 15px 0 21px;
  color: #262626;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.35px;
  line-height: 26px;
}
.project-card__strategy strong {
  font-weight: 500;
}

.project-card__return-value {
  color: #445FBF;
  font-family: "Corsica Rambler LX";
  font-size: 48px;
  line-height: 60px;
}
.project-card__return-value > span {
  color: #262626;
  font-size: 13px;
  line-height: 15px;
}

.project-card__return-value span {
  margin-left: 5px;
}

.project-card__additional-info {
  color: #262626;
  font-size: 13px;
  line-height: 15px;
  margin-top: 10px;
}

.project-card__more-info {
  text-align: right;
  margin-top: 20px;
  color: #314260;
  font-size: 13px;
  line-height: 15px;
}

.project-card__strategy span::after {
  content: ", ";
}

.project-card__strategy span:last-child::after {
  content: " ";
}

.project-card__term {
  color: #445FBF;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
}

.project-card__term:last-child {
  color: #489CB7;
}

.project-card__term span {
  color: #A4A4A4;
}

.project-card__addinfo-wrapper {
  display: flex;
  justify-content: space-between;
  min-height: 40px;
}