.pledge-range {
  margin-bottom: 30px;
}

.pledge-range__title {
  color: #3B3B3B;
  font-family: "Corsica Rambler LX";
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 30px;
}

.pledge-range__slider input {
  width: 100%;
}

.pledge-range__min-max {
  color: #3B3B3B;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.pledge-range__input label {
  margin-right: 30px;
  margin-left: 0;
  color: #383838;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}