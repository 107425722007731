.agreements-list {
  padding: 0 10px;
  .list-headers__selector-dropdown_opened {
    height: auto;
  }
  tr {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  td, th {
    display: block;
    flex: 5;
    min-width: 30px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .small-width {
    flex: 1;
  }
  .medium-width {
    flex: 3;
  }
  .large-width {
    flex: 6;
  }
  .break-words {
    word-break: break-word;
  }
  .menu-cell {
    max-width: 40px;
    }
  }
  .agreements-list__header-joined {
    min-width: 400px;
    display: flex;
    justify-content: space-between;
  }
  .agreements-list__header .projects-list__header2 {
    padding: 0 45px;
  }
  .agreements-list__header .project-list__selector-dropdown_opened {
    height: 295px;
  }


.agreements-list__header {

  h1 {
    color: #333333;
    font-family: "Corsica Rambler LX";
    font-size: 32px;
    font-weight: bold;
    line-height: 48px;
  }
}

.agreements-list__wrapper {
  table {
    width: 100%;
    border-collapse: collapse;
  }
  .small-width {
    width: 100px;
    white-space: nowrap;
  }
  td, th {
    text-align: left;
    padding: 10px;
    min-height: 65px;
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    &.right {
      text-align: right;
      .project-list__th-sort {
        justify-content: flex-end;
      }
    }
  }
  tbody tr:nth-of-type(2n+1) {
    background-color: #f2f3f5;
  }

  tr td:first-child, tr th:first-child {
    padding: 0 20px;
  }

  th {
    opacity: 0.5;
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  tbody tr:hover {
    background-color: #f2f3f5;
    td:first-child {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        height: 100%;
        width: 3px;
        background-color: #274bc8;
      }
    }
  }
}

.projects-list__wrapper .control {
  padding-bottom: 12px;
}
.projects-list__wrapper .control > input[type="checkbox"] ~ span {
  line-height: 24px;
}

.project-list__menu-cell {
  position: relative;
  max-width: 40px;
}

.project-list__user-settings {
  position: absolute;
  top: 60%;
  right: 40px;
  z-index: 1000;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.16);

  > a, > button {
    text-decoration: none;
    display: flex;
    padding: 16px 20px;
    line-height: 24px;
    background-color: transparent;
    align-items: center;
    border: 0;
    cursor: pointer;
    color: #333333 !important;

    &:hover {
      background-color: #F1F1F1;
    }

    img {
      width: 14px;
      height: 12px;
      margin-right: 10px;
    }
  }
}
.project-list__th-sort {
  display: flex;
  cursor: pointer;
}
.agreements-list__active-header {
  color: #4B74FF;
}
.strategy-list-item:not(:last-child)::after {
  content: ', ';
}
.list-headers__header2-tabs {
  width: 500px;
  height: 26px;
  display: flex;
  justify-content: space-around;
}
.list-headers__tab {
  opacity: 0.5;
  flex: 1;
  user-select: none;
  text-align: center;
  a {
    text-decoration: none;
    color: #333333!important;
    font-family: "Corsica Rambler LX";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
  }
  &_active {
    opacity: 1;
    border-bottom: 4px solid #333333;
  }
}
.agreements-list td.agreements-status {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  > div {
    text-transform: capitalize;
  }
  &::before {
    content: '';
    flex: 0 0 12px;
    height: 12px;
    width: 12px;
    margin-right: 15px;
    border-radius: 12px;
  }
  &--ACTIVE::before {
    background-color: #86DE52;
  }
  &--COMPLETED::before {
    background-color: #4B74FF;
  }
  &--CANCELLED::before {
    background-color: #f46775;
  }
}

.agreements__menu a, .agreements__menu button{
  width: 210px;
  color: #333333;
  font-size: 14px!important;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
  svg {
    margin-right: 5px;
  }
}
.agreement-logs__pdf, .agreement-frame {
  width: 100%;
  height: 800px;
}