.onblack-logo, .onwhite-logo {
  display: block;
  width: 240px;
  height: 62px;
  margin-left: -30px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url("../assets/onblack-logo.png");
}

.onwhite-logo {
  margin-left: 0;
  width: 300px;
  background-image: url("../assets/onwhite-logo.png");
}