.signable__type-container {
  width: 350px;
}
.signable__type-container input {
  border: 0 none;
  box-shadow: none;
  padding: 20px 0;
  width: 100%;
  display: block;
  border-bottom: 1px solid #ECEAEA;
  color: #414141;
  font-family: "Corsica Rambler LX";
  font-size: 20px;
  line-height: 25px;
}
.signable__type-container canvas {
  display: block;
}
.signable__type-container .signable__type-error {
  color: red;
}