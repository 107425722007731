/* Blue scheme */
/* Dark scheme */
/* Grey scheme */
.navigation ul {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  list-style-type: none;
}
.navigation a, .navigation button {
  display: flex;
  align-items: center;
  min-height: 32px;
  margin: 0 32px;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
}
.navigation.dark a, .navigation.dark button {
  color: #fff;
}
.navigation.dark a {
  color: #fff;
}
.navigation.grey a {
  color: #262626;
}