/* Blue scheme */
/* Dark scheme */
/* Grey scheme */
.login {
  display: flex;
  background-color: #FAFBFE;
}

.login__description {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 6px;
}

.login__required-tip {
  font-size: 12px;
  font-weight: 300;
  line-height: 26px;
  margin: 0 0 12px;
}

.login__right {
  flex: 1;
  background-color: #3955B7;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center;
  background-image: url(../assets/login-right.svg);
}

.login__left {
  align-items: center;
  display: flex;
  flex: 1;
  padding-bottom: 75px;
}

.login-wrapper {
  max-width: 380px;
  margin: 0 auto;
}

.login__link {
  color: #314260;
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  display: block;
  text-align: center;
  background: transparent;
  border: 0 none;
  box-shadow: none;
  text-decoration: underline;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.login__link:visited {
  color: #314260;
}