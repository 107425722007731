.project-details__pledge-wrapper {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .52);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity ease .25s;
}
.project-details__pledge-wrapper_hidden {
    opacity: 0;
    z-index: -1;
}
