.admin-payments__table {
  .expanded {
    background-color: #DAE1F6;
  }
  .status-icon {
    position: relative;
    button:nth-child(2) {
      position: absolute;
      top: 38%;
      left: -125px;
      background-color: #fff;
      padding: 15px 18px;
      min-width: 150px;
      border: none;
      box-shadow: 0 2px 8px 0 rgba(0,0,0,0.16);
      img {
        margin-right: 5px;
      }
      &:hover {
        background-color: #F2F3F5;
      }
    }
  }
}

.admin-payments__button {
  border: none;
  appearance: none;
  cursor: pointer;
  background-color: transparent;
}
