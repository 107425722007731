.draft-projects__card {
  min-height: 170px;
  flex: 1;
  display: flex;
}
.draft-projects__info {
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  display: flex;
  padding-left: 25px;
  align-items: center;
}
.draft-projects__percent {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  margin-left: 7px;
  color :#4A4A4A;
}
.draft-projects__percent span {
  font-size: 12px;
  line-height: 14px;
}
.draft-projects__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}
.draft-projects__indicator-wrapper {
  background: #5A84FB;
  height: 5px;
  border-radius: 2px;
  margin: 21px 25px 0 25px;
}
.draft-projects__indicator {
  background: #F2F2F2;
  height: 5px;
  border-radius: 2px;
}