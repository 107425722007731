/* Blue scheme */
/* Dark scheme */
/* Grey scheme */
.registration__eye {
  position: absolute;
  right: 16px;
  top: 12px;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
}
.registration__eye .svg-eye {
  width: 16px;
  height: 9px;
}

.registration__password {
  position: relative;
}

.registration__check a {
  margin-left: 20px;
  user-select: none;
  font-size: 11px;
  line-height: 17px;
}

.high-password-error {
  padding-bottom: 26px !important;
}
.high-password-error .control__error {
  height: 26px;
  align-items: flex-start;
}

.registration__check a:focus::after {
  border: none;
}