.graph-container {
  position: relative;
  background: white;
  border-radius: 10px;
  margin: auto;
  width: 368px;
}

.graph-container .projects-count {
  margin: 0;
  text-transform: uppercase;
  color: #000A12;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  height: 62px;
  position: relative;
  top: 130px;
  text-align: center;
  z-index: 0;

  p {
    margin: 0;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 38px;
  }
}

.graph-container .legend, .graph-container .multi-legend{
  width: 480px;
  padding: 20px 13px;
  border-radius: 10px;
  background-color: #FCFCFC;
  box-shadow: 0 2px 4px 0 rgba(224,224,224,0.5);
  column-count: 2;
  column-gap: 40px;
  position: relative;
  left: calc(50% - 240px);
  max-height: 260px;
  overflow: auto;

  div {
   width: 12px;
   height: 12px;
  }

  &__item {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    padding: 5px 0;
    letter-spacing: 0;
    line-height: 16px;
  }
  &__title {
    margin-left: 10px;
    font-weight: 300;
  }
  &__percent {
    margin-left: auto;
    font-weight: 500;
  }
}

.graph-container .multi-legend {
    width: 314px;
    margin: 25px auto;
    column-count: 1;
    left: 0;
}
