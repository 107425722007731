.top-nav {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}

.top-nav__main-nav-toggle {
  background: transparent;
  border: 0 none;
  outline: 0;
  box-shadow: none;
  padding: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .svg_burger {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
}

.top-nav__user-menu {
  position: relative;
}

.top-nav__user {
  display: flex;
  align-items: center;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 10px;

  .top-nav__userpic {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background: url('../../assets/profile.png');
    background-size: cover;
    margin-right: 10px;
  }
}

.top-nav--right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-nav__user-settings {
  position: absolute;
  top: 90%;
  right: 15%;
  padding: 16px 0;
  z-index: 1000;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.16);
  background-color: #FFFFFF;
  width: 160px;
  height: 92px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  &.logout-only {
    height: auto;
  }
  .top-nav__menu-item {
    display: flex;
    line-height: 24px;
    width: 100%;
    min-width: 110px;
    align-items: flex-start;
    border: 0;
    border-radius: 4px;
    padding-left: 7px;
    text-decoration: none;
    background-color: #FFFFFF;
    cursor: pointer;
    font-weight: 300;
    font-size: 14px;
    color: #333333;
    &:hover {
      background-color: #F1F1F1;
    }

    .svg_logout, .svg_profile {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    .svg_profile {
      margin-left: -1px;
    }
  }
}

.top-nav__registration-link {
  margin-right: 30px;
}

.send-registration-modal__content {
  > p {
    margin-top: 25px;
    color: #656D78;
    font-size: 18px;
    line-height: 34px;
    strong {
      font-weight: bold;
    }
  }
}

.send-registration-modal__success {
  color: #274bc8;
  text-align: center;
}
.disabled-menu {
  pointer-events: none;
  opacity: .1;
}
.top-nav__user-settings a:focus::after {
  border: none;
}