.e-signature-confirm-form p {
  color: #656D78;
  font-size: 18px;
  letter-spacing: -0.1px;
  line-height: 34px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.e-signature-confirm-form-submit {
  text-align: center;
}