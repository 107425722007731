.profile-header__button.profile-header__item_active {
  border-bottom: 1px solid white;
  font-weight: 600;
}

.profile-header__wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 151px;
  background-color: #3F63DC;
}

.profile-header__center {
  width: 500px;
}

.profile-header__center-top {
  height: 105px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 2.5px;
  text-align: left;
  color: white;
  position: relative;
}

.profile-header__center-bottom {
  height: 45px;
  display: flex;
  align-items: flex-end;
}

.profile-header__button {
  width: 50%;
  background: transparent;
  border: none;
  font-family: "Corsica Rambler LX";
  font-size: 14px;
  line-height: 18px;
  color: white;
  outline: 0;
  border-bottom: 1px solid #3F63DC;
  cursor: pointer;
}

.profile-header__avatar {
  width: 106px;
  height: 106px;
  align-self: center;
  margin-right: 28px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-image: url(../../../assets/profile.png);
}

.profile-header__person-type {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 1.5px;
  text-transform: capitalize;
}