
.card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 86px;
  padding: 36px 35px 0 27px;
}

.card-header svg {
  margin-left: 10px;
}
