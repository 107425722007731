/* Blue scheme */
/* Dark scheme */
/* Grey scheme */
.modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease;
}

.modal__backdrop {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.52);
  cursor: pointer;
}

.modal__container {
  position: relative;
  min-height: 405px;
  max-height: 80vh;
  width: 540px;
  border-radius: 11px;
  background-color: #FFFFFF;
  padding: 50px 100px;
  z-index: 100;
  overflow-y: auto;
}
.modal__container.blue {
  background-color: #3955B7;
}

.modal__close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
}
.modal__close-button .svg_cancel {
  height: 20px;
  width: 20px;
}