.radiobutton-container {
  display: block;
  position: relative;
  cursor: pointer;
  padding-left: 27px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radiobutton-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radiobutton-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid #D4D9DD;
  border-radius: 50%;
}

.radiobutton-checkmark:after {
  content: "";
  display: none;
}

.radiobutton-container input:checked ~ .radiobutton-checkmark:after {
  display: block;
}

.radiobutton-container .radiobutton-checkmark:after {
  width: 10px;
  height: 10px;
  position: relative;
  border-radius: 50%;
  background: #274BC8;
}