.pledgeproc-stage__mango {
  margin-left: -60px;
  margin-right: -50px;
  display: flex;
  padding-bottom: 37px;
}

.pledgeproc-stage__mango-left {
  width: 47.5%;
  padding-left: 65px;
}

.pledgeproc-stage__mango-right {
  width: 52.5%;
}

.pledgeproc-stage__mango-text:first-of-type {
  padding: 32px 0 5px 0;
}

.pledgeproc-stage__mango-text:last-of-type {
  padding-top: 15px;
}

.pledgeproc-stage__mango-text {
  max-width: 268px;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: #1B2125;
}

.pledgeproc-stage__mango-text span {
  font-weight: 700;
}

.pledgeproc-stage__mango + .pledgeproc-buttons {
  padding: 0 65px;
}

.pledgeproc-stage__mango-right {
  background: #3955B7;
  color: white;
  padding: 0 50px;
}

.pledgeproc-stage__mango-logo {
  min-height: 110px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pledgeproc-stage__mango-data {
  padding-bottom: 21px;
  border-bottom: 2px solid white;
}

.pledgeproc-stage__mango-row {
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 9px;
  justify-content: space-between;
}

.pledgeproc-stage__mango-value {
  font-weight: 700;
}

.pledgeproc-stage__mango-instruction {
  color: white;
}

.pledgeproc-stage__mango-transer {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding-top: 27px;
  padding-bottom: 12px;
}

.pledgeproc-stage__mango-step {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 9px;
}

.pledgeproc-stage__mango-step span {
  font-weight: 500;
}

.pledgeproc-stage__mango-confirm {
  position: relative;
}

.pledgeproc-stage__mango-confirm .pledgeproc__back-button {
  padding-left: 65px;
}