.multi-file-upload-container {
  width: 100%;
  display: flex;
  cursor: pointer;
}
.multi-file-upload-container .dropzone {
  outline: none;
}

.multi-file-upload-container__files {
  margin-left: 20px;
}

.multi-file-upload-button {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  height: 144px;
  align-items: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23274BC8FF' stroke-width='2' stroke-dasharray='9' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 24px 10px;
  border-radius: 7.5px;
  outline: 0;
}

.multi-file-upload-icons {
  display: flex;
  align-items: flex-end;
}

.multi-file-upload-title {
  margin-top: 15px;
  text-align: center;
  font-size: 15px;
  line-height: 20px;
}
.multi-file-upload-title span {
  color: #304FFE;
}